// Component - Feature Slider
$(function () {
  function initSlider () {
    var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#js-slider'

    var slider = {height: 0}

    // Update the size of slide container
    function resizeSlider () {
      slider.height = $(selector + ' .js-slides li').height()
      $(selector + ' .js-slides').css({height: slider.height})
    }

    // On load, resize, resize slider
    $(window).on('load', resizeSlider)

    // On window resize, resize slider
    $(window).on('resize', resizeSlider)

    // Go to the selected slide if the control buttons are clicked
    $(selector + ' .js-controls ul li').on('click', nextSlide)

    // Show the next slide
    function nextSlide (event) {
      // Determine the current slide
      var currentSlide      = $(selector + ' .js-slides .js-is-current-slide')
      var currentSlideIndex = parseInt(currentSlide.attr('id').substr(9))

      // Determine the next slide based on the control clicked
      var clickedControl = $(event.target).closest('li')
      var nextSlideIndex = clickedControl.index() + 1
      var nextSlide      = $(selector + ' .js-slides #js-slide-' + nextSlideIndex)

      // The active slide was clicked
      if (nextSlideIndex === currentSlideIndex) return

      // Set active slide class
      $(selector + ' .js-slides li').removeClass('js-is-current-slide')
      nextSlide.addClass('js-is-current-slide')

      // Set active control class
      $(selector + ' .js-controls li').removeClass('js-is-active-control')
      $(selector + ' .js-controls li').removeClass('js-is-expanded')
      clickedControl.addClass('js-is-active-control')
      clickedControl.addClass('js-is-expanded')

      // Determine if we need to scroll up or down
      if (nextSlideIndex > currentSlideIndex) scrollDown()
      if (nextSlideIndex < currentSlideIndex) scrollUp()

      function scrollDown () {
        // 1. Move the slide next slide to the second spot
        nextSlide.insertAfter(currentSlide)
        // 2. Scroll down (move the container up) to reveal second slide
        $(selector + ' .js-slides ul').animate({top: -slider.height}, 350, 'easeInOutCubic', function () {
          // 3. After the animation, move the first slide to the end and reset the container scrolling
          currentSlide.appendTo(selector + ' .js-slides ul')
          $(selector + ' .js-slides ul').css('top', 0)
        })
      }

      function scrollUp () {
        // 1. Move the next slide to the start of the list
        nextSlide.prependTo(selector + ' .js-slides ul')
        // 2. Immediately we scroll down (move the container up) to show the second slide
        $(selector + ' .js-slides ul').css('top', -slider.height)
        // 3. Scroll up (move the container down) to show the first slide again
        $(selector + ' .js-slides ul').animate({top: 0}, 350, 'easeInOutCubic', function () {})
      }
    }
  }

  initSlider()
});

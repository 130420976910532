$(window).scroll(function () {
    if (!$(".js-sticky-wrapper").length) return;

    var top = $(window).scrollTop();
    var stickyWrapper = $(".js-sticky-wrapper")
    var stickyContent = $(".js-sticky-content")

    if (stickyWrapper.offset().top < top) {
        stickyContent.addClass("js-is-sticky");
    } else {
        stickyContent.removeClass("js-is-sticky");
    }
});
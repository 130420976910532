// Set class active to first slider item
$(function () {
    $('.js-carousel-item:first').addClass('active');
});

// Next arrow
$('.js-arrow-right').click(function () {
    var currentText = $('.js-carousel-item.active');
    var nextText = currentText.next();


    currentText.fadeOut(300).hide().removeClass('active');
    nextText.fadeIn(300).addClass('active');

    if (nextText.length == 0) {
        $('.js-carousel-item').first().hide().fadeIn(300).addClass('active');
    }
});

// Previous arrow
$('.js-arrow-left').click(function () {
    var currentText = $('.js-carousel-item.active');
    var prevText = currentText.prev();

    currentText.fadeOut(300).hide().removeClass('active');
    prevText.fadeIn(300).addClass('active');

    if (prevText.length == 0) {
        $('.js-carousel-item').last().hide().fadeIn(300).addClass('active');
    }
});